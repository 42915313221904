import axios from 'axios';

export default class OrderService {

	getOrdersSmall() {
		return axios.get('assets/layout/data/products-small.json').then(res => res.data.data);
	}

	async getOrders() {
		return await axios.get('https://advantex.uz/checkout/getOrders').then(res => res.data);
	}

	getOrdersWithOrdersSmall() {
		return axios.get('assets/layout/data/products-orders-small.json').then(res => res.data.data);
	}

	deleteOrder(order) {
		console.log(order)
	}


	acceptOrder(order_id) {

		var data = JSON.stringify({
			"id": order_id
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/checkout/acceptOrder',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)

	}
}