<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedOrders || !selectedOrders.length" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="orders" class="p-datatable-responsive-demo" v-model:selection="selectedOrders" dataKey="order_info.order_id"
					:paginator="true" :rows="10" :filters="filters" :rowsPerPageOptions="[5,10,25]" :loading="loading1"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage Orders</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<!-- <template #loading>
						Loading customers data. Please wait.
					</template> -->
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="order_info.order_id" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.order_info.order_id}}
						</template>
					</Column>
					<Column field="user_info.company" header="Company" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Company</span>
							{{slotProps.data.user_info.company}}
						</template>
					</Column>
					<Column field="order_info.order_date" header="Date" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Date</span>
							{{slotProps.data.order_info.order_date}}
						</template>
					</Column>
					<Column field="order_info.status" header="Status" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Status</span>
							{{slotProps.data.order_info.status}}
						</template>
					</Column>
					<Column headerStyle="width: 15%" header="Actions">
						<template #body="slotProps">
							<Button icon="pi pi-check" class="p-button-rounded p-button-primary p-mr-2" @click="accept_order(slotProps.data)" v-tooltip.top="'Accept'" :disabled="slotProps.data.order_info.status == 'PAID'"/>
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="viewOrderDetails(slotProps.data)" v-tooltip.top="'View'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteOrder(slotProps.data)" v-tooltip.top="'Delete'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog header="Order Information" v-model:visible="displayOrderModal" :style="{width: '60vw'}" :modal="true">
					<div class="p-col-12">
						<div class="card">
							<h5>Order detail</h5>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-6 p-md-3">
									<label for="order_id" class="p-text-light">Order ID</label>
									<br/>
									<div id="order_id" class="p-text-bold">{{ selectedOrder.order_info.order_id }}</div>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="total_amount" class="p-text-light">Total Amount</label>
									<br/>
									<div id="total_amount" class="p-text-bold">{{ formatCurrency(parseInt(selectedOrder.order_info.to_be_paid)) }}</div>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="status" class="p-text-light">Status</label>
									<br/>
									<div id="status" class="p-text-bold">{{ selectedOrder.order_info.status }}</div>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="order_date" class="p-text-light">Date {{selectedOrder.products_array[0].product_code}}</label>
									<br/>
									<div id="order_date" class="p-text-bold">{{ selectedOrder.order_info.order_date }}</div>
								</div>
							</div>
						</div>
						<hr>
						<div class="card">
							<h5>Customer detail</h5>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-6 p-md-3">
									<label for="customer_name" class="p-text-light">First name</label>
									<br/>
									<div id="customer_name" class="p-text-bold">{{ selectedOrder.user_info.first_name }}</div>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="customer_lastname" class="p-text-light">Last Name</label>
									<br/>
									<div id="customer_lastname" class="p-text-bold">{{ selectedOrder.user_info.last_name }}</div>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="customer_company" class="p-text-light">Company</label>
									<br/>
									<div id="customer_company" class="p-text-bold">{{ selectedOrder.user_info.company }}</div>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="customer_phone" class="p-text-light">Phone</label>
									<br/>
									<div id="customer_phone" class="p-text-bold">{{ selectedOrder.user_info.phone }}</div>
								</div>
							</div>
						</div>
						<hr>
						<DataTable 
							:value="selectedOrder.products_array" :lazy="true" class="p-datatable-responsive-demo" 
							:paginator="true" :rows="10" :rowsPerPageOptions="[5,10,25]"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} ordered products" :loading="loading2">
							<template #header>
								<div class="table-header">
									<h5 class="p-m-0">Ordered Products</h5>
								</div>
							</template>
							<template #loading>
								Loading order's data. Please wait.
							</template>
							<Column field="product_code" header="Code" :sortable="true"></Column>
							<Column field="product_name" header="Name" :sortable="true"></Column>
							<Column field="length" header="Length (mm)" :sortable="true"></Column>
							<Column field="width" header="Width (mm)" :sortable="true"></Column>
							<Column field="product_price" header="Cost" :sortable="true"></Column>
							<Column field="count" header="Count" :sortable="true"></Column>
						</DataTable>
					</div>
					<template #footer>
						<Button label="Dismiss" @click="closeViewModal" icon="pi pi-times" class="p-button-secondary"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteOrderDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="order">Are you sure you want to delete <b>{{order.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrderDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteOrder" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteOrdersDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="order">Are you sure you want to delete the selected orders?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrdersDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedOrders" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import OrderService from '../service/OrderService';

export default {
	data() {
		return {
			orders: [],
			selectedOrder: {},
			rawData: {},
			orderDialog: false,
			deleteOrderDialog: false,
			deleteOrdersDialog: false,
			order: {},
			selectedOrders: null,
			filters: {},
			loading1:true,
			loading2:true,
			submitted: false,
			dropdownItem: null,
			displayOrderModal: false,
		}
	},
	orderService: null,
	created() {
		this.orderService = new OrderService();
		
	},
	mounted() {
		this.onMounted()
	},
	methods: {
		onMounted() {
			this.orders = []
			this.orderService.getOrders().then(data => {
				if(data.isLogged==false) {
					this.$router.push('Login')
				}
				else {
					this.rawData = data.data;
					for (const [key, value] of Object.entries(this.rawData)) {
						value.products_array = []
						for (let index in value.products_info){
							value.products_array.push(JSON.parse(value.products_info[index].product_info))
						}
						this.orders.push(value);
						console.log(key)
					}
					this.loading1 = false
				}
			});
		},

		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'UZS'});
		},
		closeViewModal(){
			this.displayOrderModal = false
		},
		viewOrderDetails(order) {
			this.selectedOrder = order
			this.loading2 = false
			for (let index in this.selectedOrder.products_array) {
				this.selectedOrder.products_array[index].product_price = this.formatCurrency(parseInt(this.selectedOrder.products_array[index].product_cost))
			}
			this.displayOrderModal = true
		},
		
		accept_order(order){
			this.orderService.acceptOrder(order.order_info.order_id)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		
		},
		confirmDeleteOrder(order) {
			this.order = order;
			this.deleteOrderDialog = true;
		}, 
		deleteOrder() {
			this.orderService.deleteOrder(this.order)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.deleteOrderDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		},
		confirmDeleteSelected() {
			this.deleteOrdersDialog = true;
		},
		deleteSelectedOrders() {
			this.orders = this.orders.filter(val => !this.selectedOrders.includes(val));
			this.deleteOrdersDialog = false;
			this.selectedOrders = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Orders Deleted', life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.order-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.ordertype-image-modal {
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 1rem 0;
	display: block;
}
.p-dialog .order-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-published {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-saved {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
